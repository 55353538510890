import React, { useState } from 'react';
import './App.css';
import axios from 'axios';

function App() {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      var config = {
        headers: {'Access-Control-Allow-Origin': '*'}
    };
      const response = await axios.get('https://worker-delicate-pine-62bf.wixfly.workers.dev/', config);
      setText(response.data.display_text);
    } catch (error) {
      console.error('Error fetching data', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <div className="text">{text || "bla bla"}</div>
      <button 
        className="button" 
        onClick={handleClick} 
        disabled={loading}
      >
        {loading ? 'Please wait...' : 'Click Me'}
      </button>
    </div>
  );
}

export default App;